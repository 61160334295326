import { request, METHOD } from "@/utils/request";
import { addurl } from "@/utils/util";
import { requestApiUrl } from "@/config/constConfig";
/**
 * 发送验证码
 */
 export async function sendMessage(params) {
    return request(
      addurl("sendMessage", requestApiUrl.requestAccount) + '/' + params.phNumber,
      METHOD.GET
    );
  }
//   注册
export async function register(params) {
    return request(
      addurl("register", requestApiUrl.requestAccount),
      METHOD.POST,
      params
    );
  }
  export async function getInfo(doi) {
    return request(
      addurl("getInfo", requestApiUrl.requestGetUserInfo) + '/' + doi,
      METHOD.GET
    );
  }