import { request, METHOD } from "@/utils/request";
import { addurl } from "@/utils/util";
import { requestApiUrl } from "@/config/constConfig";
/**
 * 登录
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param order 排序字段
 */
 export async function login(params) {
    return request(
      addurl("login", requestApiUrl.requestAccount) + '?' + 'p=' + params.password + '&u=' + params.name,
      METHOD.POST
    );
  }