<template>
  <div>
    <div id="main">
      <div class="container">
        <div class="form">
          <h3>登录</h3>
          <a-form-model
            layout="horizontal"
            :model="loginForm"
            ref="ruleForm"
            :rules="rules"
          >
            <a-form-model-item prop="user">
              <a-input v-model="loginForm.user" placeholder="请输入手机号码">
                <a-icon
                  slot="prefix"
                  type="user"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="password">
              <a-input
                v-model="loginForm.password"
                type="password"
                placeholder="请输入密码"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item
              prop="code"
              class="valcode"
              v-show="isShowValCode"
            >
              <a-input
                v-model="loginForm.code"
                type="Captcha"
                placeholder="请输入验证码"
                style="width: 55%; float: left"
              >
                <a-icon slot="prefix" type="safety-certificate" />
              </a-input>
              <div style="text-align: right; width: 40%" id="v_container"></div>
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                type="primary"
                html-type="submit"
                @click="submitForm('ruleForm')"
              >
                登录
              </a-button>
            </a-form-model-item>
          </a-form-model>
          <div class="input-group link clearfix">
            <!-- <a class="f1">忘记密码？</a> -->
            <a class="sigin fr" @click="goSwitch('registered')">立即注册</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GVerify from "@/utils/validateCode.js";
import * as Api from "@/services/login";
import { mapGetters, mapMutations } from "vuex";
import { setAuthorization } from "@/utils/request";
import { isMobile } from "@/utils/util";
export default {
  name: "login",
  components: {},
  data() {
    var vm = this;
    let validatePass = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入手机号"));
      } else {
        let reg = /^1([3|5|6|7|8|9])\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("手机号格式有错误"));
        }
      }
    };
    let validatePass2 = (rule, value, callback) => {
      // if (value === "" || !value) {
      //   callback(new Error("请输入密码"));
      // } else {
      //   callback();
      // }
       var reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,12}$/     
       if (value === "" || !value) {
        callback(new Error("请输入密码"));
      } else {
        if (value.length < 8 || value.length > 12) {
          callback(new Error("密码长度为8到12位"));
        } else {
           if (reg.test(value)) {
            callback();
          } else {
            callback(new Error("密码必须是大写字母，小写字母，数字组成！"));
          }
        }
      }
    };
    let validatevcode = (rule, value, callback) => {
      if (vm.isShowValCode && !vm.verifyCode.validate(value)) {
        callback(new Error("验证码不正确"));
      } else {
        callback();
      }
    };
    return {
      isShowValCode: false,
      loginForm: {
        //用户名密码
        user: "",
        password: "",
        code: "", //验证码
      },
      rules: {
        //校验规则
        user: [{ validator: validatePass, trigger: "change" }],
        password: [{ validator: validatePass2, trigger: "change" }],
        code: [{ validator: validatevcode, trigger: "change" }],
      },
    };
  },
  created() {
    this.Mobile();
  },
  computed: {
    ...mapGetters("account", ["oldRouter"]),
  },
  mounted() {
    this.verifyCode = new GVerify({
      id: "v_container",
      canvasId: "accountverifyCanvas",
    });
  },
  methods: {
    ...mapMutations("account", [
      "setUser",
      "setRoles",
      "setExpireAt",
      "saveNowRouter",
    ]),
    vCodeChange() {
      this.verifyCode = new GVerify({
        id: "v_container",
        canvasId: "accountverifyCanvas",
      });
    },
    Mobile() {
      if (isMobile()) {
        this.$router.push({ name: "mobileLogin" });
      }
    },
    //去注册
    goSwitch() {
      this.$router.push({ name: "Register" });
    },
    //登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            password: this.loginForm.password,
            name: this.loginForm.user,
          };
          //   alert("submit!");
          Api.login(obj).then((res) => {
            if (res.success) {
              this.setRoles(res.data.role);
              this.setUser(res.data.username);
              this.setExpireAt(res.data.expireAt);
              setAuthorization({
                token: res.data.token,
                expireAt: new Date(res.data.expireAt).getTime(),
              });
              if (!this.oldRouter) {
                this.$router.push("/");
              } else if (this.oldRouter.name === "pdfRead") {
                this.$router.push({
                  name: this.oldRouter.name,
                  query: { pdfUrl: this.oldRouter.query.pdfUrl },
                });
              } else {
                this.$router.push({
                  name: this.oldRouter.name,
                  params: this.oldRouter.params,
                });
              }
              this.saveNowRouter(undefined);
              this.$message.success("登录成功");
              // this.$notification["success"]({
              //   message: "成功",
              //   description: "登录成功",
              //   placement: "topLeft",
              //   style: {
              //     width: "500px",
              //   },
              // });
            } else {
              this.isShowValCode = true;
              this.$message.warning(res.message);
              // this.$notification["warning"]({
              //   message: "警告",
              //   description: res.message,
              //   placement: "topLeft",
              //   style: {
              //     width: "500px",
              //   },
              // });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
@import "~@/assets/css/home.less";
</style>
<style lang="less" scoped>
#main {
  width: 100%;
  height: 752px;
  margin-top: -56px;
  background: url(~@/assets/images/login-bg.jpg) no-repeat top center;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  .container {
    height: 752px;
    position: relative;
    #v_container {
      float: left;
      margin-left: 5%;
    }
    .form {
      width: 366px;
      min-height: 312px;
      padding: 30px;
      border: 2px solid #ffcaca;
      background: #fff;
      border-radius: 12px;
      position: absolute;
      right: 0px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      transform: translateY(-50%);
      h3 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 30px;
      }
      .getPass {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 114px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #ca0d19;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
      }
      .count {
        background: #ccc;
      }
      .link {
        .sigin {
          color: #0273ff;
        }
      }
    }
  }
}
</style>
<style scoped>
.container >>> .ant-form-item-children {
  width: 100%;
}
.container >>> .ant-form-item {
  margin-bottom: 0;
}
.container >>> .ant-form-item-control-wrapper {
  height: 65px;
}
.container >>> .ant-form-item span i {
  font-size: 23px;
}
.container >>> .ant-form-item-children input {
  height: 40px;
  padding-left: 40px;
}
.container >>> .ant-form-item-children button {
  width: 100%;
  height: 44px;
  line-height: 44px;
  background: #ca0d19;
  border-radius: 6px;
  font-size: 16px;
  color: #fff;
  touch-action: manipulation;
  cursor: pointer;
  margin-top: 10px;
}
.valcode >>> .ant-form-item-control {
  line-height: 17px;
}
</style>
