<template>
  <div>
    <div id="main">
      <div class="container">
        <div class="form">
          <h3>注册</h3>
          <a-form-model
            layout="horizontal"
            :model="registeredFrom"
            ref="registeredFrom"
            :rules="registeredRules"
          >
            <a-form-model-item prop="user">
              <a-input
                v-model="registeredFrom.user"
                placeholder="请输入手机号码"
              >
                <a-icon
                  slot="prefix"
                  type="user"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
              <span v-show="show" class="getPass" @click="getCode"
                >获取验证码</span
              >
              <span v-show="!show" class="getPass count">{{ count }} s</span>
            </a-form-model-item>
            <a-form-model-item prop="verificationCode">
              <a-input
                v-model="registeredFrom.verificationCode"
                placeholder="请输入验证码"
              >
                <a-icon
                  slot="prefix"
                  type="safety-certificate"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="password">
              <a-input
                v-model="registeredFrom.password"
                type="password"
                placeholder="请输入密码"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="cpassword">
              <a-input
                v-model="registeredFrom.cpassword"
                type="password"
                placeholder="请确认密码"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="invitationCode">
              <a-input
                v-model="registeredFrom.invitationCode"
                placeholder="请输入邀请码"
              >
                <a-icon
                  slot="prefix"
                  type="mail"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                type="primary"
                html-type="submit"
                @click="registered('registeredFrom')"
              >
                注册
              </a-button>
            </a-form-model-item>
          </a-form-model>
          <div class="input-group link clearfix">
            <a class="sigin fr" @click="goSwitch('login')"
              >已有账号，立即登录</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from "@/services/register";
import { isMobile } from "@/utils/util";
export default {
  name: "login",
  components: {},
  data() {
    var vm = this;
    let validatePass5 = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入手机号"));
      } else {
        let reg = /^1([3|5|6|7|8|9])\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("手机号格式有错误"));
        }
      }
    };
    let validatePass3 = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入验证码"));
      } else {
        if (this.verificationCode === value) {
          callback();
        } else {
          callback(new Error("验证码不正确"));
        }
      }
    };
    let validatePass4 = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入邀请码"));
      } else {
        callback();
      }
    };
    let validatePass6 = (rule, value, callback) => {
      //let reg = /[^\w]/;
      //console.log(1)
      //let reg="^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)$"
      var reg =
        /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,12}$/;
      if (value === "" || !value) {
        callback(new Error("请输入密码"));
      } else {
        if (value.length < 8 || value.length > 12) {
          callback(new Error("密码长度为8到12位"));
        } else {
           if (reg.test(value)) {
            callback();
          } else {
            callback(new Error("密码必须是大写字母，小写字母，数字组成！"));
          }
        }
      }
    };
    let validatePass7 = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入确认密码"));
      } else {
        if (value !== vm.registeredFrom.password) {
          callback(new Error("确认密码与密码不一致！"));
        } else {
          callback();
        }
      }
    };
    return {
      verificationCode: "", //验证码
      timer: null, //计时器
      show: true, //控制计时器显示
      count: "", //时间
      registeredFrom: {
        //注册条件
        user: "",
        verificationCode: "",
        password: "",
        cpassword: "",
        invitationCode: "",
      },
      registeredRules: {
        //注册校验
        user: [{ validator: validatePass5, trigger: "change" }],
        verificationCode: [{ validator: validatePass3, trigger: "change" }],
        password: [{ validator: validatePass6, trigger: "change" }],
        cpassword: [{ validator: validatePass7, trigger: "change" }],
        invitationCode: [{ validator: validatePass4, trigger: "change" }],
      },
    };
  },
  created() {
    this.Mobile();
  },
  methods: {
    Mobile() {
      if (isMobile()) {
        this.$router.push({ name: "mobileIndex" });
      }
    },
    //去登录
    goSwitch() {
      this.$router.push({ name: "Login" });
    },
    //获取验证码
    getCode() {
      let reg = /^1([3|5|6|7|8|9])\d{9}$/;
      if (
        !this.registeredFrom.user ||
        this.registeredFrom.user === "" ||
        !reg.test(this.registeredFrom.user)
      ) {
        return;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      let obj = {
        phNumber: this.registeredFrom.user,
      };
      Api.sendMessage(obj).then((res) => {
        if (res.success) {
          this.verificationCode = res.data.code;
          this.$message.success(res.message);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    //注册
    registered(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            iccord: this.registeredFrom.invitationCode,
            password: this.registeredFrom.password,
            username: this.registeredFrom.user,
          };
          //   alert("已注册!");
          Api.register(obj).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.registeredFrom = {
                //注册条件
                user: "",
                verificationCode: "",
                password: "",
                cpassword: "",
                invitationCode: "",
              };
            } else {
              this.$message.warning(res.message);
            }
          });
        } else {
          console.log("error 注册!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
@import "~@/assets/css/home.less";
</style>
<style lang="less" scoped>
#main {
  width: 100%;
  height: 752px;
  margin-top: -56px;
  background: url(~@/assets/images/login-bg.jpg) no-repeat top center;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  .container {
    height: 752px;
    position: relative;
    .form {
      width: 366px;
      min-height: 312px;
      padding: 30px;
      border: 2px solid #ffcaca;
      background: #fff;
      border-radius: 12px;
      position: absolute;
      right: 0px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      transform: translateY(-50%);
      h3 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 30px;
      }
      .getPass {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 114px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #ca0d19;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
      }
      .count {
        background: #ccc;
      }
      .link {
        .sigin {
          color: #0273ff;
        }
      }
    }
  }
}
</style>
<style scoped>
.container >>> .ant-form-item-children {
  width: 100%;
}
.container >>> .ant-form-item {
  margin-bottom: 0;
}
.container >>> .ant-form-item-control-wrapper {
  height: 65px;
}
.container >>> .ant-form-item span i {
  font-size: 23px;
}
.container >>> .ant-form-item-children input {
  height: 40px;
  padding-left: 40px;
}
.container >>> .ant-form-item-children button {
  width: 100%;
  height: 44px;
  line-height: 44px;
  background: #ca0d19;
  border-radius: 6px;
  font-size: 16px;
  color: #fff;
  touch-action: manipulation;
  cursor: pointer;
  margin-top: 10px;
}
</style>
