<template>
  <div>
    <div id="main">
      <div class="newcontainer">
        <div class="form">
          <h3>注册</h3>
          <a-form-model
            layout="horizontal"
            :model="registeredFrom"
            ref="registeredFromUser"
            :rules="registeredRules"
            v-if="isshow"
          >
            <a-form-model-item prop="user">
              <a-input
                v-model="registeredFrom.user"
                placeholder="请输入手机号码"
              >
                <a-icon
                  slot="prefix"
                  type="user"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
              <span v-show="show" class="getPass" @click="getCode"
                >获取验证码</span
              >
              <span v-show="!show" class="getPass count">{{ count }} s</span>
            </a-form-model-item>
            <a-form-model-item prop="verificationCode">
              <a-input
                v-model="registeredFrom.verificationCode"
                placeholder="请输入验证码"
              >
                <a-icon
                  slot="prefix"
                  type="safety-certificate"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
          </a-form-model>
          <a-form-model
            layout="horizontal"
            :model="registeredFrom"
            ref="registeredFrom"
            :rules="registeredRules"
            v-if="!isshow"
          >
            <a-form-model-item prop="password">
              <a-input
                v-model="registeredFrom.password"
                type="password"
                placeholder="请输入密码"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="cpassword">
              <a-input
                v-model="registeredFrom.cpassword"
                type="password"
                placeholder="请确认密码"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="invitationCode">
              <a-input
                v-model="registeredFrom.invitationCode"
                placeholder="请输入邀请码（必填）"
              >
                <a-icon
                  slot="prefix"
                  type="mail"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
          </a-form-model>
          <div class="button" v-if="isshow" :class="{ red: isfinish }">
            <a-button
              type="primary"
              html-type="submit"
              @click="getUser('registeredFromUser')"
            >
              下一步
            </a-button>
          </div>
          <div class="button" v-if="!isshow" :class="{ red: isfinishreg }">
            <a-button
              type="primary"
              html-type="submit"
              @click="registered('registeredFrom')"
            >
              完成
            </a-button>
          </div>
          <div class="link clearfix">
            已有账号，
            <a class="sigin fr" @click="goSwitch()">立即登录</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from "@/services/register";
import { isMobile } from "@/utils/util";
export default {
  name: "MobileRegister",
  components: {},
  data() {
    var vm = this;
    let validatePass5 = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入手机号"));
      } else {
        let reg = /^1([3|5|6|7|8|9])\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("手机号格式有错误"));
        }
      }
    };
    let validatePass3 = (rule, value, callback) => {
      if (value === "" || !value) {
        this.isfinishreg = false;
        callback(new Error("请输入验证码"));
      } else {
        if (this.Code === value) {
          if (this.show) {
            callback(new Error("验证码已过期"));
          } else {
            if (value.length === 6) {
              this.isfinish = !this.isfinish;
              callback();
            }
          }
          // this.isfinish = !this.isfinish;
          // callback();
        } else {
          this.isfinishreg = false;
          callback(new Error("验证码不正确"));
        }
      }
    };
    let validatePass4 = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入邀请码"));
        this.isfinishreg = false;
      } else {
        if (value.length === 8) {
          this.isfinishreg = !this.isfinishreg;
          callback();
        } else {
          this.isfinishreg = false;
          callback(new Error("邀请码格式错误"));
        }
      }
    };
    let validatePass6 = (rule, value, callback) => {
     // let reg = /[^\w]/;
     var reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,12}$/     
      if (value === "" || !value) {
        callback(new Error("请输入密码"));
      } else {
        if (value.length < 8 || value.length > 12) {
          callback(new Error("密码长度为8到12位"));
        } else {
           if (reg.test(value)) {
            callback();
          } else {
            callback(new Error("密码必须是大写字母，小写字母，数字组成！"));
          }
        }
      }
    };
    let validatePass7 = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入确认密码"));
      } else {
        if (value !== vm.registeredFrom.password) {
          callback(new Error("确认密码与密码不一致！"));
        } else {
          callback();
        }
      }
    };
    return {
      isfinish: false,
      isfinishreg: false,
      isshow: true,
      Code: "", //验证码
      timer: null, //计时器
      show: true, //控制计时器显示
      count: "", //时间
      registeredFrom: {
        //注册条件
        user: "",
        verificationCode: "",
        password: "",
        cpassword: "",
        invitationCode: "",
      },
      registeredRules: {
        //注册校验
        user: [{ validator: validatePass5, trigger: "change" }],
        verificationCode: [{ validator: validatePass3, trigger: "change" }],
        password: [{ validator: validatePass6, trigger: "change" }],
        cpassword: [{ validator: validatePass7, trigger: "change" }],
        invitationCode: [{ validator: validatePass4, trigger: "change" }],
      },
    };
  },
  created() {
    this.Mobile();
  },
  methods: {
    Mobile() {
      if (!isMobile()) {
        this.$router.push({ name: "Register" });
      }
    },
    //去登录
    goSwitch() {
      this.$router.push({ name: "mobileLogin" });
    },
    //获取验证码
    getCode() {
      let reg = /^1([3|5|6|7|8|9])\d{9}$/;
      if (
        !this.registeredFrom.user ||
        this.registeredFrom.user === "" ||
        !reg.test(this.registeredFrom.user)
      ) {
        return;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      let obj = {
        phNumber: this.registeredFrom.user,
      };
      Api.sendMessage(obj).then((res) => {
        if (res.success) {
          this.Code = res.data.code;
          this.$message.success(res.message);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    getUser(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let username = this.registeredFrom.user;
          //   alert("已注册!");
          Api.getInfo(username).then((res) => {
            if (!res.success) {
              this.isshow = !this.isshow;
            } else {
              this.$message.warning("用户已存在");
            }
          });
        } else {
          console.log("error 注册!!");
          return false;
        }
      });
    },
    //注册
    registered(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            iccord: this.registeredFrom.invitationCode,
            password: this.registeredFrom.password,
            username: this.registeredFrom.user,
          };
          //   alert("已注册!");
          Api.register(obj).then((res) => {
            if (res.success) {
              this.$message.success("注册成功，请登录");
              this.Code=''
              this.registeredFrom = {
                //注册条件
                user: "",
                verificationCode: "",
                password: "",
                cpassword: "",
                invitationCode: "",
              };
              this.$router.push({ name: 'mobileLogin' });
            } else {
              this.$message.warning(res.message);
            }
          });
        } else {
          console.log("error 注册!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
@import "~@/assets/css/home.less";
</style>
<style lang="less" scoped>
#main {
  width: 100%;
  height: 100vh;
  background: url(~@/assets/images/login-bg.png) no-repeat top center;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  .newcontainer {
    height: 100vh;
    position: relative;
    .form {
      width: 90%;
      margin-left: 5%;
      padding: 0.4rem;
      border: 2px solid #ffcaca;
      background: #fff;
      border-radius: 5px;
      position: absolute;
      top: 19%;
      h3 {
        font-size: 0.5rem;
        margin-bottom: 0.6rem;
        font-weight: bolder;
      }
      .getPass {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 2.28rem;
        height: 0.8rem;
        line-height: 0.8rem;
        text-align: center;
        //background: #ca0d19;
        background: url(~@/assets/images/btn-bg.png) no-repeat top center;
        font-size: 0.28rem;
        color: #fff;
        cursor: pointer;
      }
      .count {
        background: #ccc;
      }
      .link {
        position: absolute;
        margin-top: 50%;
        /* text-align: center; */
        margin-left: 26%;
        .sigin {
          color: #0273ff;
        }
      }
    }
  }
}
</style>
<style scoped>
.newcontainer >>> .ant-form-item-children {
  width: 100%;
}
.newcontainer >>> .ant-form-item {
  margin-bottom: 0;
}
.newcontainer >>> .ant-form-item-control-wrapper {
  height: 1.3rem;
}
.newcontainer >>> .ant-form-item span i {
  font-size: 0.46rem;
}
.newcontainer >>> .ant-form-item-children input {
  height: 0.8rem;
  padding-left: 0.8rem;
}
.newcontainer .button.red >>> .ant-btn-primary {
  position: absolute;
  margin-top: 22%;
  margin-left: -7%;
  background: url(~@/assets/images/btn-bg.png) no-repeat top center;
  background-size: 100% 100%;
  width: 6.8rem;
  height: 0.9rem;
  border-radius: 5px;
  background-color: #ca0d19;
  border-color: #d4000e;
}
.newcontainer .button >>> .ant-btn-primary {
  border-color: grey;
  position: absolute;
  margin-top: 22%;
  margin-left: -7%;
  background-color: grey;
  width: 6.8rem;
  height: 0.9rem;
  border-radius: 5px;
}
</style>
