<template>
  <div>
    <div id="main">
      <div class="newcontainer">
        <div class="form">
          <h3>登录</h3>
          <a-form-model
            layout="horizontal"
            :model="loginForm"
            ref="ruleForm"
            :rules="rules"
          >
            <a-form-model-item prop="user" label="账户手机号">
              <a-input v-model="loginForm.user" placeholder="请输入手机号码">
                <a-icon
                  slot="prefix"
                  type="user"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="password" label="账户密码">
              <a-input
                v-model="loginForm.password"
                type="password"
                placeholder="请输入密码"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item
              prop="code"
              class="valcode"
              v-show="isShowValCode"
            >
              <a-input
                v-model="loginForm.code"
                type="Captcha"
                placeholder="请输入验证码"
                style="width: 55%; float: left"
              >
                <a-icon slot="prefix" type="safety-certificate" />
              </a-input>
              <div style="text-align: right; width: 40%" id="v_container"></div>
            </a-form-model-item>
          </a-form-model>
          <div class="button">
            <a-button
              type="primary"
              html-type="submit"
              @click="submitForm('ruleForm')"
            >
              登录
            </a-button>
          </div>
          <div class="link clearfix">
            没有账号？
            <a class="sigin fr" @click="goSwitch('mobileRegister')">立即注册</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GVerify from "@/utils/validateCode.js";
import * as Api from "@/services/login";
import { mapGetters, mapMutations } from "vuex";
import { setAuthorization } from "@/utils/request";
import { isMobile } from "@/utils/util";
export default {
  name: "MobileLogin",
  components: {},
  data() {
    var vm = this;
    let validatePass = (rule, value, callback) => {
      if (value === "" || !value) {
        callback(new Error("请输入手机号"));
      } else {
        let reg = /^1([3|5|6|7|8|9])\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("手机号格式有错误"));
        }
      }
    };
    let validatePass2 = (rule, value, callback) => {
      // if (value === "" || !value) {
      //   callback(new Error("请输入密码"));
      // } else {
      //   callback();
      // }
       var reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,12}$/     
       if (value === "" || !value) {
        callback(new Error("请输入密码"));
      } else {
        if (value.length < 8 || value.length > 12) {
          callback(new Error("密码长度为8到12位"));
        } else {
           if (reg.test(value)) {
            callback();
          } else {
            callback(new Error("密码必须是大写字母，小写字母，数字组成！"));
          }
        }
      }
    };
    let validatevcode = (rule, value, callback) => {
      if (vm.isShowValCode && !vm.verifyCode.validate(value)) {
        callback(new Error("验证码不正确"));
      } else {
        callback();
      }
    };
    return {
      isShowValCode: false,
      loginForm: {
        //用户名密码
        user: "",
        password: "",
        code: "", //验证码
      },
      rules: {
        //校验规则
        user: [{ validator: validatePass, trigger: "change" }],
        password: [{ validator: validatePass2, trigger: "change" }],
        code: [{ validator: validatevcode, trigger: "change" }],
      },
    };
  },
  created() {
    this.Mobile();
  },
  computed: {
    ...mapGetters("account", ["oldRouter"]),
  },
  mounted() {
    this.verifyCode = new GVerify({
      id: "v_container",
      canvasId: "accountverifyCanvas",
      width: "100",
      height: "40",
    });
  },
  methods: {
    ...mapMutations("account", [
      "setUser",
      "setRoles",
      "setExpireAt",
      "saveNowRouter",
    ]),
    vCodeChange() {
      this.verifyCode = new GVerify({
        id: "v_container",
        canvasId: "accountverifyCanvas",
        width: "100",
        height: "40",
      });
    },
    Mobile() {
      if (!isMobile()) {
        this.$router.push({ name: "login" });
      }
    },
    //去注册
    goSwitch(name) {
      this.$router.push({ name: name });
    },
    //登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            password: this.loginForm.password,
            name: this.loginForm.user,
          };
          //   alert("submit!");
          Api.login(obj).then((res) => {
            if (res.success) {
              this.setRoles(res.data.role);
              this.setUser(res.data.username);
              this.setExpireAt(res.data.expireAt);
              setAuthorization({
                token: res.data.token,
                expireAt: new Date(res.data.expireAt).getTime(),
              });
              if (!this.oldRouter) {
                this.$router.push({
                  name: "mobileIndex",
                });
              } else if (this.oldRouter.name === "pdfRead") {
                this.$router.push({
                  name: this.oldRouter.name,
                  query: { pdfUrl: this.oldRouter.query.pdfUrl },
                });
              } else {
                this.$router.push({
                  name: this.oldRouter.name,
                  params: this.oldRouter.params,
                });
              }
              this.saveNowRouter(undefined);
              this.$message.success("登录成功");
            } else {
              this.isShowValCode = true;
              this.$message.warning(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less">
@import "~@/assets/css/home.less";
</style>
<style lang="less" scoped>
#main {
  width: 100%;
  height: 100vh;
  background: url(~@/assets/images/login-bg.png) no-repeat top center;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  .newcontainer {
    height: 100vh;
    position: relative;
    #v_container {
      float: left;
      margin-left: 5%;
    }
    .form {
      width: 90%;
      margin-left: 5%;
      padding: 0.4rem;
      border: 2px solid #ffcaca;
      background: #fff;
      border-radius: 5px;
      position: absolute;
      top: 19%;

      h3 {
        font-size: 0.5rem;
        margin-bottom: 0.6rem;
        font-weight: bolder;
      }
      .getPass {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 2.28rem;
        height: 0.8rem;
        line-height: 0.8rem;
        text-align: center;
        background: #ca0d19;
        font-size: 0.28rem;
        color: #fff;
        cursor: pointer;
      }
      .count {
        background: #ccc;
      }
      .link {
        position: absolute;
        margin-top: 50%;
        /* text-align: center; */
        margin-left: 26%;
        .sigin {
          color: #0273ff;
        }
      }
    }
  }
}
</style>
<style scoped>
.newcontainer >>> .ant-form-item-children {
  width: 100%;
}
.newcontainer >>> .ant-form-item {
  margin-bottom: 0;
}
.newcontainer >>> .ant-form-item label {
  font-size: 0.28rem;
  color: #787878;
}
.newcontainer >>> .ant-form-item-control-wrapper {
  height: 1.3rem;
}
.newcontainer >>> .ant-form-item span i {
  font-size: 0.46rem;
}
.newcontainer >>> .ant-form-item-children input {
  height: 0.8rem;
  padding-left: 0.8rem;
}
.newcontainer .button >>> .ant-btn-primary {
  /* width: 100%;
  height: 44px;
  line-height: 44px;
  background: #ca0d19;
  border-radius: 6px;
  font-size: 16px;
  color: #fff;
  touch-action: manipulation;
  cursor: pointer;
  margin-top: 10px; */
  position: absolute;
  margin-top: 15%;
  margin-left: -7%;
  background: url(~@/assets/images/btn-bg.png) no-repeat top center;
  background-size: 104% 100%;
  width: 6.8rem;
  height: 0.9rem;
  border-radius: 5px;
}
.valcode >>> .ant-form-item-control {
  line-height: 0.34rem;
}
</style>
